import * as React from "react";
import { CustomerNotifications } from "src/components/shared/CustomerNotifications";
import { CustomerNotificationsWrapperProps } from "./typings";
import { CustomerNotificationsInPage } from "src/components/shared/StorefrontWizard/TravelAdvisory/CustomerNotificationsInPage";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { observer } from "mobx-react";
import { useProvidedState } from "@shared-ui/provided-state-context";
import { CustomerNotificationStateEvent } from "@shared-ui/customer-notifications";
import { EGDSHeading } from "@egds/react-core/text";

export const CustomerNotificationsWrapper = observer((props: CustomerNotificationsWrapperProps) => {
  const { templateComponent } = props;

  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }

  const {
    metadata: { id },
    config: { location, className, reasonsToBelieve, reasonsToBelieveVersion, title },
  } = templateComponent;

  const [state] = useProvidedState();

  if (!location) {
    return null;
  }

  const shouldDisplayTitle =
    title &&
    state?.customerNotificationControlState?.[location]?.state ===
      CustomerNotificationStateEvent.CustomerNotificationInitialized;

  const wrapperTitle = shouldDisplayTitle && (
    <EGDSSpacing padding={{ blockend: "three" }}>
      <EGDSHeading tag="h2" size={3} data-testid="customer-notifications-wrapper-title">
        {title}
      </EGDSHeading>
    </EGDSSpacing>
  );

  const customerNotificationsInPage = (
    <div id={id} className="ReasonsToBelieveWrapper" data-testid="reasons-to-believe-wrapper">
      {wrapperTitle}
      <CustomerNotificationsInPage reasonsToBelieveVersion={reasonsToBelieveVersion} />
    </div>
  );

  return reasonsToBelieve && location === "IN_PAGE" ? (
    customerNotificationsInPage
  ) : (
    <div id={id} className="CustomerNotificationsWrapper" data-testid="customer-notifications-wrapper">
      {wrapperTitle}
      <CustomerNotifications location={location} className={className} />
    </div>
  );
});

export default CustomerNotificationsWrapper;
